import React from 'react';
import { useTranslation } from 'react-i18next';

const Summary: React.FC = () => {
    const { t } = useTranslation('legal', { keyPrefix: 'imprint' });

    return (
        <>
            <p>
                <b>OVERVIEW GmbH</b>, Nachtigallenweg 29, DE-50259 Pulheim
            </p>
            <p>
                {t('phone')}: <a href="tel:004915238045978">+49 152 38 04 59 78</a>
            </p>
            <p>
                {t('email')}: <a href="mailto:management@overview.de">management@overview.de</a>
            </p>
            <p>
                <p>{t('representedBy')}: Jonas Lieske, Josua Mittelstaedt, Lukas Menzner</p>
            </p>
            <p>
                <p>{t('registryCourt')}: Amtsgericht Köln Registernummer HRB 113012</p>
            </p>
            <p>
                <p>{t('taxID')}: DE357873785</p>
            </p>
            <p>
                <p>{t('contentLiable')}: Jonas Lieske, Josua Mittelstaedt, Lukas Menzner</p>
            </p>
        </>
    );
};

export default Summary;
