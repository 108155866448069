import React from 'react';
import { useTranslation } from 'react-i18next';

const StorageDuration: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'generalNotes.storageDuration' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content')}</p>
        </>
    );
};

export default StorageDuration;
