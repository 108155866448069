import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoBox, DoBoxType, EfficientImage, Grid } from '@overview-services/ovcomponents-react';

const IconDonts: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'iconography.iconDonts' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid count={4}>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_1')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_2')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_3')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_4')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_5')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_6')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_7')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_8')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage url={''} blurHash={''}></EfficientImage>
                    <p>{t('dont_9')}</p>
                </DoBox>
            </Grid>
            {/*// TODO add images*/}
        </>
    );
};

export default IconDonts;
