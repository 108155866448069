import React from 'react';
import { useTranslation } from 'react-i18next';
import { EfficientImage, EfficientImageZoom, Grid, GridArea } from '@overview-services/ovcomponents-react';

const Intent: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'introduction.intent' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid>
                <GridArea>
                    <p>{t('content_1')}</p>
                    <p>{t('content_2')}</p>
                    <p>{t('content_3')}</p>
                    <p>{t('content_4')}</p>
                </GridArea>
                <EfficientImage
                    className={'signature'}
                    url={'https://library.overview.de/pages/style-guide/intent--signature.png'}
                    blurHash={'00TSUA'}
                    zoom={EfficientImageZoom.fit}
                    height={'auto'}
                    width={'250px'}
                ></EfficientImage>
            </Grid>
        </>
    );
};

export default Intent;
