import React from 'react';
import { useTranslation } from 'react-i18next';

const Logs: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'dataCollection.logs' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('loggedData.content')}</p>
            <ul>
                <li>{t('loggedData.dataType_1')}</li>
                <li>{t('loggedData.dataType_2')}</li>
                <li>{t('loggedData.dataType_3')}</li>
                <li>{t('loggedData.dataType_4')}</li>
                <li>{t('loggedData.dataType_5')}</li>
                <li>{t('loggedData.dataType_6')}</li>
            </ul>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
        </>
    );
};

export default Logs;
