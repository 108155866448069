import React from 'react';
import { useTranslation } from 'react-i18next';

const RegistrationRequirements: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'responsibilitiesLiabilities.registrationRequirements' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content')}</p>
            <ul>
                <li>{t('requirements.age')}</li>
                <li>{t('requirements.legality')}</li>
                <li>{t('requirements.noSuspension')}</li>
                <li>{t('requirements.noConviction')}</li>
            </ul>
        </>
    );
};

export default RegistrationRequirements;
