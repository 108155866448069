import React from 'react';
import { useTranslation } from 'react-i18next';

const Liability: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'legalReservation.liability' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
        </>
    );
};

export default Liability;
