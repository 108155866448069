import React from 'react';
import { useTranslation } from 'react-i18next';

const ServiceDisturbance: React.FC = () => {
    const { t } = useTranslation('termsOfUse', {
        keyPrefix: 'responsibilitiesLiabilities.misdemeanour.serviceDisturbance',
    });

    return (
        <>
            <h4>{t('title')}</h4>
            <p>{t('content')}</p>
        </>
    );
};

export default ServiceDisturbance;
