import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupType, LogoTypes } from '@overview-services/ovcomponents-react';
import { useLocation } from 'react-router-dom';
import { createContentDataLegal } from './content_pages/Legal/contentDataLegal';
import { createContentDataPrivacyPolicy } from './content_pages/Privacy-Policy/contentDataPrivacyPolicy';
import { createContentDataAGBOVUser } from './content_pages/AGB-OV-User/contentDataAGBOVUser';
import { createContentDataAGBOVCorp } from './content_pages/AGB-OV-Corp/contentDataAGBOVCorp';
import { createContentDataAGBOVBooking } from './content_pages/AGB-OV-Booking/contentDataAGBOVBooking';
import { createContentDataAGBOVListings } from './content_pages/AGB-OV-Spotlight/contentDataAGBOVSpotlight';
import { createContentDataAGBOVSLA } from './content_pages/AGB-OV-SLA/contentDataAGBOVSLA';
import { createContentDataCommunityGuidelines } from './content_pages/Community-Guidelines/contentDataCommunityGuidelines';
import { createContentDataTermsOfUse } from './content_pages/Terms-Of-Use/contentDataTermsOfUse';
import { TFunction } from 'i18next';
import { createContentDataStyleGuide } from './content_pages/Style-Guide/contentDataStyleGuide';

export enum RoutePaths {
    LEGAL = '/legal',
    PRIVACY_POLICY = '/privacy-policy',
    AGB_OV_USER = '/agb-ov-user',
    AGB_OV_CORP = '/agb-ov-corp',
    AGB_OV_BOOKING = '/agb-ov-booking',
    AGB_OV_LISTINGS = '/agb-ov-listings',
    AGB_OV_SLA = '/agb-ov-sla',
    COMMUNITY_GUIDELINES = '/community-guidelines',
    TERMS_OF_USE = '/terms-of-use',
    NEWSROOM = '/newsroom',
    INVESTOR_RELATIONS = '/investor-relations',
    STYLE_GUIDE = '/style-guide',
    ASSETS = '/assets',
    API_DOCS = '/api-docs',
    USER_SUPPORT = '/user-support',
    CORPORATE_SUPPORT = '/corporate-support',
    USER_GUIDE = '/user-guide',
    CORPORATE_GUIDE = '/corporate-guide',
}

export const getLogo = (route: RoutePaths | string) => {
    switch (route) {
        case RoutePaths.LEGAL:
            return LogoTypes.business;
        case RoutePaths.PRIVACY_POLICY:
            return LogoTypes.business;
        case RoutePaths.AGB_OV_USER:
            return LogoTypes.business;
        case RoutePaths.AGB_OV_CORP:
            return LogoTypes.business;
        case RoutePaths.AGB_OV_BOOKING:
            return LogoTypes.business;
        case RoutePaths.AGB_OV_LISTINGS:
            return LogoTypes.business;
        case RoutePaths.AGB_OV_SLA:
            return LogoTypes.business;
        case RoutePaths.COMMUNITY_GUIDELINES:
            return LogoTypes.business;
        case RoutePaths.TERMS_OF_USE:
            return LogoTypes.business;
        case RoutePaths.NEWSROOM:
            return LogoTypes.business;
        case RoutePaths.INVESTOR_RELATIONS:
            return LogoTypes.investor;
        case RoutePaths.STYLE_GUIDE:
            return LogoTypes.brand;
        case RoutePaths.ASSETS:
            return LogoTypes.library;
        case RoutePaths.API_DOCS:
            return LogoTypes.developer;
        case RoutePaths.USER_SUPPORT:
            return LogoTypes.support;
        case RoutePaths.CORPORATE_SUPPORT:
            return LogoTypes.support;
        case RoutePaths.USER_GUIDE:
            return LogoTypes.default;
        case RoutePaths.CORPORATE_GUIDE:
            return LogoTypes.corporate;
        default:
            return LogoTypes.default;
    }
};

const createTWithNamespace = (t: TFunction<'translation', undefined, 'translation'>, ns: string) => {
    return (key: string, options = {}) => t(key, { ...options, ns });
};

const getContentData = (t: TFunction<'translation', undefined, 'translation'>, location: Location) => {
    let contentData: GroupType[] = [];

    if (location.pathname === RoutePaths.LEGAL) {
        contentData = createContentDataLegal(createTWithNamespace(t, 'legal'));
    } else if (location.pathname === RoutePaths.PRIVACY_POLICY) {
        contentData = createContentDataPrivacyPolicy(createTWithNamespace(t, 'privacyPolicy'));
    } else if (location.pathname === RoutePaths.AGB_OV_USER) {
        contentData = createContentDataAGBOVUser(createTWithNamespace(t, 'AGBOVUser'));
    } else if (location.pathname === RoutePaths.AGB_OV_CORP) {
        contentData = createContentDataAGBOVCorp(createTWithNamespace(t, 'AGBOVCorp'));
    } else if (location.pathname === RoutePaths.AGB_OV_BOOKING) {
        contentData = createContentDataAGBOVBooking(createTWithNamespace(t, 'AGBOVBooking'));
    } else if (location.pathname === RoutePaths.AGB_OV_LISTINGS) {
        contentData = createContentDataAGBOVListings(createTWithNamespace(t, 'AGBOVListings'));
    } else if (location.pathname === RoutePaths.AGB_OV_SLA) {
        contentData = createContentDataAGBOVSLA(createTWithNamespace(t, 'AGBOVSLA'));
    } else if (location.pathname === RoutePaths.COMMUNITY_GUIDELINES) {
        contentData = createContentDataCommunityGuidelines(createTWithNamespace(t, 'communityGuidelines'));
    } else if (location.pathname === RoutePaths.TERMS_OF_USE) {
        contentData = createContentDataTermsOfUse(createTWithNamespace(t, 'termsOfUse'));
    } else if (location.pathname === RoutePaths.STYLE_GUIDE) {
        contentData = createContentDataStyleGuide(createTWithNamespace(t, 'styleGuide'));
    }

    return contentData;
};

export const useContentData = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [contentData, setContentData] = useState<GroupType[]>(getContentData(t, location as unknown as Location));

    useEffect(() => {
        setContentData(getContentData(t, location as unknown as Location));
    }, [i18n.language]);
    return contentData;
};
