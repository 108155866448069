import React from 'react';
import {
    Section,
    SectionContentColors,
    SectionHeights,
    SectionWidths,
    Grid,
    GridArea,
    GridAreaJustify,
    GridAreaAlign,
    MeshGradient,
    MeshGradientColors,
} from '@overview-services/ovcomponents-react';
import { useTranslation } from 'react-i18next';

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={'main-content'}>
            <Section
                contentColor={SectionContentColors.dark}
                id={`error404-content`}
                height={SectionHeights.stretch}
                width={SectionWidths.stretch}
                background={<MeshGradient color={MeshGradientColors.monochrome} />}
            >
                <Grid count={1}>
                    <Grid count={3} id={`title-grid`}>
                        <GridArea className="error404-title" justify={GridAreaJustify.end} align={GridAreaAlign.center}>
                            <h1>4</h1>
                        </GridArea>
                        <GridArea
                            className="error404-logo"
                            justify={GridAreaJustify.center}
                            align={GridAreaAlign.center}
                        >
                            <object
                                data="https://library.overview.de/brandidentity/logo/animation/symbol_dark_reg.svg"
                                type="image/svg+xml"
                            ></object>
                        </GridArea>
                        <GridArea
                            className="error404-title"
                            justify={GridAreaJustify.start}
                            align={GridAreaAlign.center}
                        >
                            <h1>4</h1>
                        </GridArea>
                    </Grid>
                    <GridArea id={`error404-notice`} justify={GridAreaJustify.center}>
                        <p>{t('notFoundPage.caption')}</p>
                    </GridArea>
                </Grid>
            </Section>
        </div>
    );
};

export default NotFoundPage;
