import React from 'react';
import { useTranslation } from 'react-i18next';

const Update: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'update' });

    return (
        <>
            <p>{t('content')}</p>
        </>
    );
};

export default Update;
