import React from 'react';
import { useTranslation } from 'react-i18next';

const PriorityClasses: React.FC = () => {
    const { t } = useTranslation('AGBOVSLA', { keyPrefix: 'priorityClasses' });

    return (
        <>
            <p className="level-0">{t('content_1')}</p>
            <p className="level-1">{t('content_1_1')}</p>
            <p className="level-2">{t('content_1_1a')}</p>
            <p className="level-2">{t('content_1_1b')}</p>
            <p className="level-1">{t('content_1_2')}</p>
            <p className="level-2">{t('content_1_2a')}</p>
            <p className="level-2">{t('content_1_2b')}</p>
            <p className="level-1">{t('content_1_3')}</p>
            <p className="level-2">{t('content_1_3a')}</p>
            <p className="level-2">{t('content_1_3b')}</p>
            <p className="level-1">{t('content_1_4')}</p>
            <p className="level-0">{t('content_2')}</p>
            <p className="level-1">{t('content_2_1')}</p>
            <p className="level-2">{t('content_2_1a')}</p>
            <p className="level-2">{t('content_2_1b')}</p>
            <p className="level-2">{t('content_2_1c')}</p>
            <p className="level-2">{t('content_2_1d')}</p>
            <p className="level-1">{t('content_2_2')}</p>
            <p className="level-1">{t('content_2_3')}</p>
            <p className="level-1">{t('content_2_4')}</p>
            <p className="level-0">{t('content_3')}</p>
            <p className="level-0">{t('content_4')}</p>
            <p className="level-0">{t('content_5')}</p>
        </>
    );
};

export default PriorityClasses;
