import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridArea } from '@overview-services/ovcomponents-react';

const DataProtectionOfficer: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'generalNotes.dataProtectionOfficer' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content')}</p>
            <Grid>
                <GridArea>
                    <p>
                        <b>Jonas Lieske</b>
                        <br />
                        {t('mail_subject')}
                        <br />
                        Nachtigallenweg 29
                        <br />
                        DE-50259 Pulheim
                    </p>
                    <p>
                        {t('phone')} <a href="tel:004915238045978">+49 152 38 04 59 78</a>
                    </p>
                    <p>
                        {t('email')} <a href="mailto:datenschutz@overview.de">datenschutz@overview.de</a>
                    </p>
                </GridArea>
            </Grid>
        </>
    );
};

export default DataProtectionOfficer;
