import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    EfficientImage,
    EfficientImageZoom,
    Grid,
    GridArea,
    GridAreaAlign,
    GridAreaJustify,
} from '@overview-services/ovcomponents-react';

const LogoWordmark: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'logo.logoWordmark' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid count={2}>
                <GridArea>
                    <p>{t('content_1')}</p>
                    <p>{t('content_2')}</p>
                    <p>{t('content_3')}</p>
                </GridArea>
                <GridArea justify={GridAreaJustify.stretch} align={GridAreaAlign.stretch}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/wordmark--image.png'}
                        blurHash={'00TSUA'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                </GridArea>
            </Grid>
        </>
    );
};

export default LogoWordmark;
