import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@overview-services/ovcomponents-react';

const Hosting: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'hosting' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
            <p>{t('content_3')}</p>
            <p>{t('hostingServices')}</p>
            <Grid count={1}>
                <p>
                    <b>Microsoft Corporation</b>
                    <br />
                    One Microsoft Way
                    <br />
                    Redmond, WA 98052-6399
                    <br />
                    USA
                </p>
            </Grid>
        </>
    );
};

export default Hosting;
