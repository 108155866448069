import React from 'react';
import { useTranslation } from 'react-i18next';

const Inclusivity: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'about.serviceCoverage.inclusivity' });

    return (
        <>
            <h4>{t('title')}</h4>
            <p>{t('content')}</p>
        </>
    );
};

export default Inclusivity;
