import React from 'react';
import { useTranslation } from 'react-i18next';

const ServiceCoverage: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'about.serviceCoverage' });

    return (
        <>
            <p>{t('content')}</p>
        </>
    );
};

export default ServiceCoverage;
