import React from 'react';
import { useTranslation } from 'react-i18next';

const ProcessingRestrictions: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'generalNotes.processingRestrictions' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('cases.content')}</p>
            <ul>
                <li>{t('cases.case_1')}</li>
                <li>{t('cases.case_2')}</li>
                <li>{t('cases.case_3')}</li>
                <li>{t('cases.case_4')}</li>
                <li>{t('cases.case_5')}</li>
            </ul>
        </>
    );
};

export default ProcessingRestrictions;
