import React from 'react';
import { useTranslation } from 'react-i18next';

const Here: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'thirdParty.here' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>
                {t('content_1')}{' '}
                <a href="https://legal.here.com/en-gb/privacy/policies/privacy-statement">
                    https://legal.here.com/en-gb/privacy/policies/privacy-statement
                </a>
            </p>
            <p>
                {t('content_2')}{' '}
                <a href="https://legal.here.com/de-de/privacy/policies/privacy-statement">
                    https://legal.here.com/de-de/privacy/policies/privacy-statement
                </a>
            </p>
        </>
    );
};

export default Here;
