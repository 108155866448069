import React from 'react';
import { useTranslation } from 'react-i18next';

const HumanInterface: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'applications.humanInterface' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content')}</p>
            {/*// TODO add content*/}
        </>
    );
};

export default HumanInterface;
