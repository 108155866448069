import Summary from './Summary';
import Hosting from './Hosting';
import Logs from './DataCollection/Logs';
import Firebase from './ThirdParty/Firebase';
import Here from './ThirdParty/Here';
import Crisp from './Support/Crisp';
import Contact from './DataCollection/Contact';
import { getContentPage, GroupType } from '@overview-services/ovcomponents-react';
import DataProtection from './GeneralNotes/DataProtection';
import ResponsibleEntity from './GeneralNotes/ResponsibleEntity';
import StorageDuration from './GeneralNotes/StorageDuration';
import DataProtectionOfficer from './GeneralNotes/DataProtectionOfficer';
import DataTransfer from './GeneralNotes/DataTransfer';
import ConsentWithdrawal from './GeneralNotes/ConsentWithdrawal';
import ConsentWithdrawalSpecial from './GeneralNotes/ConsentWithdrawalSpecial';
import RightOfAppeal from './GeneralNotes/RightOfAppeal';
import DataPortability from './GeneralNotes/DataPortability';
import DataInformation from './GeneralNotes/DataInformation';
import ProcessingRestrictions from './GeneralNotes/ProcessingRestrictions';
import Encryption from './GeneralNotes/Encryption';

export const createContentDataPrivacyPolicy = (
    t: (key: string, options?: NonNullable<unknown>) => string,
): GroupType[] => [
    {
        groupTitle: t('summary.title'),
        children: [getContentPage('', Summary)],
    },
    {
        groupTitle: t('generalNotes.title'),
        children: [
            getContentPage(t('generalNotes.dataProtection.title'), DataProtection),
            getContentPage(t('generalNotes.responsibleEntity.title'), ResponsibleEntity),
            getContentPage(t('generalNotes.storageDuration.title'), StorageDuration),
            getContentPage(t('generalNotes.dataProtectionOfficer.title'), DataProtectionOfficer),
            getContentPage(t('generalNotes.dataTransfer.title'), DataTransfer),
            getContentPage(t('generalNotes.consentWithdrawal.title'), ConsentWithdrawal),
            getContentPage(t('generalNotes.consentWithdrawalSpecial.title'), ConsentWithdrawalSpecial),
            getContentPage(t('generalNotes.rightOfAppeal.title'), RightOfAppeal),
            getContentPage(t('generalNotes.dataPortability.title'), DataPortability),
            getContentPage(t('generalNotes.dataInformation.title'), DataInformation),
            getContentPage(t('generalNotes.processingRestrictions.title'), ProcessingRestrictions),
            getContentPage(t('generalNotes.encryption.title'), Encryption),
        ],
    },
    {
        groupTitle: t('hosting.title'),
        children: [getContentPage('', Hosting)],
    },
    {
        groupTitle: t('thirdParty.title'),
        children: [
            getContentPage(t('thirdParty.firebase.title'), Firebase),
            getContentPage(t('thirdParty.here.title'), Here),
        ],
    },
    {
        groupTitle: t('support.title'),
        children: [getContentPage(t('support.crisp.title'), Crisp)],
    },
    {
        groupTitle: t('dataCollection.title'),
        children: [
            getContentPage(t('dataCollection.logs.title'), Logs),
            getContentPage(t('dataCollection.contact.title'), Contact),
        ],
    },
];
