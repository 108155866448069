import { getContentPage, getNoTitleContentPage, GroupType } from '@overview-services/ovcomponents-react';
import Introduction from './Introduction';
import Intent from './Introduction/Intent';
import AssetUsage from './Introduction/AssetUsage';
import BrandStrategy from './Introduction/BrandStrategy';
import BrandPurpose from './Introduction/BrandPurpose';
import BrandVision from './Introduction/BrandVision';
import BrandValues from './Introduction/BrandValues';
import TargetAudience from './Introduction/TargetAudience';
import BrandPositioning from './Introduction/BrandPositioning';
import BrandPersonality from './Introduction/BrandPersonality';
import Logo from './Logo';
import LogoWordmark from './Logo/LogoWordmark';
import LogoSymbol from './Logo/LogoSymbol';
import LogoConstruction from './Logo/LogoConstruction';
import LogoComposition from './Logo/LogoComposition';
import LogoSafeArea from './Logo/LogoSafeArea';
import LogoSizing from './Logo/LogoSizing';
import LogoColors from './Logo/LogoColors';
import LogoPlacement from './Logo/LogoPlacement';
import LogoDonts from './Logo/LogoDonts';
import ColorPalette from './ColorPalette';
import PrimaryColors from './ColorPalette/PrimaryColors';
import AllColors from './ColorPalette/AllColors';
import ColorBalance from './ColorPalette/ColorBalance';
import ColorDonts from './ColorPalette/ColorDonts';
import Typography from './Typography';
import PrimaryTypeface from './Typography/PrimaryTypeface';
import IdentityTypeface from './Typography/IdentityTypeface';
import FontSizingSpacing from './Typography/FontSizingSpacing';
import FontDonts from './Typography/FontDonts';
import Iconography from './Iconography';
import IconConstruction from './Iconography/IconConstruction';
import IconSizingSpacing from './Iconography/IconSizingSpacing';
import IconPlacement from './Iconography/IconPlacement';
import IconDonts from './Iconography/IconDonts';
import Photography from './Photography';
import PhotographySelection from './Photography/PhotographySelection';
import ImageComposition from './Photography/ImageComposition';
import ImageDonts from './Photography/ImageDonts';
import EmotionStorytelling from './EmotionStorytelling';
import ExperienceDesign from './EmotionStorytelling/ExperienceDesign';
import Language from './EmotionStorytelling/Language';
import MotionDesign from './EmotionStorytelling/MotionDesign';
import TactileDesign from './EmotionStorytelling/TactileDesign';
import Applications from './Applications';
import StationaryDesign from './Applications/StationaryDesign';
import HumanInterface from './Applications/HumanInterface';
import EditorialDesign from './Applications/EditorialDesign';
import PromotionalItems from './Applications/PromitionalItems';
import AssetLibrary from './AssetLibrary';

export const createContentDataStyleGuide = (
    t: (key: string, options?: NonNullable<unknown>) => string,
): GroupType[] => [
    {
        groupTitle: t('introduction.title'),
        children: [
            getNoTitleContentPage(Introduction),
            getContentPage(t('introduction.intent.title'), Intent),
            getContentPage(t('introduction.assetUsage.title'), AssetUsage),
            getContentPage(t('introduction.brandStrategy.title'), BrandStrategy),
            getContentPage(t('introduction.brandPurpose.title'), BrandPurpose),
            getContentPage(t('introduction.brandVision.title'), BrandVision),
            getContentPage(t('introduction.brandValues.title'), BrandValues),
            getContentPage(t('introduction.targetAudience.title'), TargetAudience),
            getContentPage(t('introduction.brandPositioning.title'), BrandPositioning),
            getContentPage(t('introduction.brandPersonality.title'), BrandPersonality),
        ],
    },
    {
        groupTitle: t('logo.title'),
        children: [
            getNoTitleContentPage(Logo),
            getContentPage(t('logo.logoWordmark.title'), LogoWordmark),
            getContentPage(t('logo.logoSymbol.title'), LogoSymbol),
            getContentPage(t('logo.logoConstruction.title'), LogoConstruction),
            getContentPage(t('logo.logoComposition.title'), LogoComposition),
            getContentPage(t('logo.logoSafeArea.title'), LogoSafeArea),
            getContentPage(t('logo.logoSizing.title'), LogoSizing),
            getContentPage(t('logo.logoColors.title'), LogoColors),
            getContentPage(t('logo.logoPlacement.title'), LogoPlacement),
            getContentPage(t('logo.logoDonts.title'), LogoDonts),
        ],
    },
    {
        groupTitle: t('colorPalette.title'),
        children: [
            getNoTitleContentPage(ColorPalette),
            getContentPage(t('colorPalette.primaryColors.title'), PrimaryColors),
            getContentPage(t('colorPalette.allColors.title'), AllColors),
            getContentPage(t('colorPalette.colorBalance.title'), ColorBalance),
            getContentPage(t('colorPalette.colorDonts.title'), ColorDonts),
        ],
    },
    {
        groupTitle: t('typography.title'),
        children: [
            getNoTitleContentPage(Typography),
            getContentPage(t('typography.primaryTypeface.title'), PrimaryTypeface),
            getContentPage(t('typography.identityTypeface.title'), IdentityTypeface),
            getContentPage(t('typography.fontSizingSpacing.title'), FontSizingSpacing),
            getContentPage(t('typography.fontDonts.title'), FontDonts),
        ],
    },
    {
        groupTitle: t('iconography.title'),
        children: [
            getNoTitleContentPage(Iconography),
            getContentPage(t('iconography.iconConstruction.title'), IconConstruction),
            getContentPage(t('iconography.iconSizingSpacing.title'), IconSizingSpacing),
            getContentPage(t('iconography.iconPlacement.title'), IconPlacement),
            getContentPage(t('iconography.iconDonts.title'), IconDonts),
        ],
    },
    {
        groupTitle: t('photography.title'),
        children: [
            getNoTitleContentPage(Photography),
            getContentPage(t('photography.photographySelection.title'), PhotographySelection),
            getContentPage(t('photography.imageComposition.title'), ImageComposition),
            getContentPage(t('photography.imageDonts.title'), ImageDonts),
        ],
    },
    {
        groupTitle: t('emotionStorytelling.title'),
        children: [
            getNoTitleContentPage(EmotionStorytelling),
            getContentPage(t('emotionStorytelling.experienceDesign.title'), ExperienceDesign),
            getContentPage(t('emotionStorytelling.language.title'), Language),
            getContentPage(t('emotionStorytelling.motionDesign.title'), MotionDesign),
            getContentPage(t('emotionStorytelling.tactileDesign.title'), TactileDesign),
        ],
    },
    {
        groupTitle: t('applications.title'),
        children: [
            getNoTitleContentPage(Applications),
            getContentPage(t('applications.stationaryDesign.title'), StationaryDesign),
            getContentPage(t('applications.humanInterface.title'), HumanInterface),
            getContentPage(t('applications.editorialDesign.title'), EditorialDesign),
            getContentPage(t('applications.promotionalItems.title'), PromotionalItems),
        ],
    },
    {
        groupTitle: t('assetLibrary.title'),
        children: [getNoTitleContentPage(AssetLibrary)],
    },
];
