import React from 'react';
import { useTranslation } from 'react-i18next';

const Photography: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'photography' });

    return (
        <>
            <h5>{t('content')}</h5>
        </>
    );
};

export default Photography;
