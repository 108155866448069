import { getContentPage, GroupType } from '@overview-services/ovcomponents-react';
import Definitions from '../AGB-OV-SLA/Definitions';

export const createContentDataAGBOVBooking = (
    t: (key: string, options?: NonNullable<unknown>) => string,
): GroupType[] => [
    {
        groupTitle: t('definitions.title'),
        children: [getContentPage('', Definitions)],
    },
];
