import React from 'react';
import { useTranslation } from 'react-i18next';

const PrimaryTypeface: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'typography.primaryTypeface' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
            <p>{t('content_3')}</p>
            {/*// TODO add image*/}
        </>
    );
};

export default PrimaryTypeface;
