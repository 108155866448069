import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoBox, DoBoxType, EfficientImage, EfficientImageZoom, Grid } from '@overview-services/ovcomponents-react';

const LogoDonts: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'logo.logoDonts' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid count={4}>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-1.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_1')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-2.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_2')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-3.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_3')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-4.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_4')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-5.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_5')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-6.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_6')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-7.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_7')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-8.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_8')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-9.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_9')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-10.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_10')}</p>
                </DoBox>
                <DoBox doType={DoBoxType.doNot}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/logo--dont-11.png'}
                        blurHash={'00Rfh3'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                    <p>{t('dont_11')}</p>
                </DoBox>
            </Grid>
        </>
    );
};

export default LogoDonts;
