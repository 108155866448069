import React from 'react';
import { useTranslation } from 'react-i18next';

const Copyright: React.FC = () => {
    const { t } = useTranslation('legal', { keyPrefix: 'disclaimer.copyright' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
        </>
    );
};

export default Copyright;
