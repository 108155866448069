import ServiceCoverage from './About/ServiceCoverage';
import Personalisation from './About/ServiceCoverage/Personalisation';
import Inclusivity from './About/ServiceCoverage/Inclusivity';
import Appropriation from './About/ServiceCoverage/Appropriation';
import Intermediation from './About/ServiceCoverage/Intermediation';
import Innovation from './About/ServiceCoverage/Innovation';
import Funding from './About/Funding';
import DataSecurity from './About/DataSecurity';
import RegistrationRequirements from './ResponsibilitiesLiabilities/RegistrationRequirements';
import Misdemeanour from './ResponsibilitiesLiabilities/Misdemeanour';
import Misinformation from './ResponsibilitiesLiabilities/Misdemeanour/Misinformation';
import Illegality from './ResponsibilitiesLiabilities/Misdemeanour/Illegality';
import GuidelineViolation from './ResponsibilitiesLiabilities/Misdemeanour/GuidelineViolation';
import ServiceDisturbance from './ResponsibilitiesLiabilities/Misdemeanour/ServiceDisturbance';
import DataAccess from './ResponsibilitiesLiabilities/Misdemeanour/DataAccess';
import ProductAdaptation from './ResponsibilitiesLiabilities/Misdemeanour/ProductAdaptation';
import Authority from './ResponsibilitiesLiabilities/Authority';
import PersonalDataUsage from './ResponsibilitiesLiabilities/Authority/PersonalDataUsage';
import ContentInteraction from './ResponsibilitiesLiabilities/Authority/ContentInteraction';
import SoftwareUpdates from './ResponsibilitiesLiabilities/Authority/SoftwareUpdates';
import LegalReservation from './LegalReservation';
import InformationInadequacy from './LegalReservation/InformationInadequacy';
import StyleGuide from './LegalReservation/StyleGuide';
import ContentBlocker from './LegalReservation/ContentBlocker';
import Rights from './LegalReservation/Rights';
import Liability from './LegalReservation/Liability';
import Update from './Update';
import { getContentPage, getNoTitleContentPage, GroupType } from '@overview-services/ovcomponents-react';

export const createContentDataTermsOfUse = (
    t: (key: string, options?: NonNullable<unknown>) => string,
): GroupType[] => [
    {
        groupTitle: t('about.title'),
        children: [
            {
                groupTitle: t('about.serviceCoverage.title'),
                children: [
                    getNoTitleContentPage(ServiceCoverage),
                    getContentPage(t('about.serviceCoverage.personalisation.title'), Personalisation),
                    getContentPage(t('about.serviceCoverage.inclusivity.title'), Inclusivity),
                    getContentPage(t('about.serviceCoverage.appropriation.title'), Appropriation),
                    getContentPage(t('about.serviceCoverage.intermediation.title'), Intermediation),
                    getContentPage(t('about.serviceCoverage.innovation.title'), Innovation),
                ],
            },
            getContentPage(t('about.funding.title'), Funding),
            getContentPage(t('about.dataSecurity.title'), DataSecurity),
        ],
    },
    {
        groupTitle: t('responsibilitiesLiabilities.title'),
        children: [
            getContentPage(t('responsibilitiesLiabilities.registrationRequirements.title'), RegistrationRequirements),
            {
                groupTitle: t('responsibilitiesLiabilities.misdemeanour.title'),
                children: [
                    getNoTitleContentPage(Misdemeanour),
                    getContentPage(t('responsibilitiesLiabilities.misdemeanour.misinformation.title'), Misinformation),
                    getContentPage(t('responsibilitiesLiabilities.misdemeanour.illegality.title'), Illegality),
                    getContentPage(
                        t('responsibilitiesLiabilities.misdemeanour.guidelineViolation.title'),
                        GuidelineViolation,
                    ),
                    getContentPage(
                        t('responsibilitiesLiabilities.misdemeanour.serviceDisturbance.title'),
                        ServiceDisturbance,
                    ),
                    getContentPage(t('responsibilitiesLiabilities.misdemeanour.dataAccess.title'), DataAccess),
                    getContentPage(
                        t('responsibilitiesLiabilities.misdemeanour.productAdaptation.title'),
                        ProductAdaptation,
                    ),
                ],
            },
            {
                groupTitle: t('responsibilitiesLiabilities.authority.title'),
                children: [
                    getNoTitleContentPage(Authority),
                    getContentPage(
                        t('responsibilitiesLiabilities.authority.personalDataUsage.title'),
                        PersonalDataUsage,
                    ),
                    getContentPage(
                        t('responsibilitiesLiabilities.authority.contentInteraction.title'),
                        ContentInteraction,
                    ),
                    getContentPage(t('responsibilitiesLiabilities.authority.softwareUpdates.title'), SoftwareUpdates),
                ],
            },
        ],
    },
    {
        groupTitle: t('legalReservation.title'),
        children: [
            getNoTitleContentPage(LegalReservation),
            getContentPage(t('legalReservation.informationInadequacy.title'), InformationInadequacy),
            getContentPage(t('legalReservation.styleGuide.title'), StyleGuide),
            getContentPage(t('legalReservation.contentBlocker.title'), ContentBlocker),
            getContentPage(t('legalReservation.rights.title'), Rights),
            getContentPage(t('legalReservation.liability.title'), Liability),
        ],
    },
    {
        groupTitle: t('update.title'),
        children: [getContentPage('', Update)],
    },
];
