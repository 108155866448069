import React, { useEffect, useState } from 'react';
import { getLogo, RoutePaths, useContentData } from './data/contentData';
import {
    BackToTop,
    ContentList,
    Footer,
    Header,
    Language,
    LanguageSwitcher,
    LayoutTypes,
    Sidebar,
} from '@overview-services/ovcomponents-react';
import { useTranslation } from 'react-i18next';
import './styles/main.sass';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import { supportedLanguages } from './i18n';

const App: React.FC = () => {
    const { t, i18n } = useTranslation('translation');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.slice(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);

    const sideBarEmbedded = document.getElementById('sidebar') != null;

    let currentPath = window.location.pathname;
    if (process.env.PUBLIC_URL && currentPath.startsWith(process.env.PUBLIC_URL)) {
        currentPath = currentPath.substring(process.env.PUBLIC_URL.length);
    }

    return (
        <div className="App">
            <Header
                logoType={getLogo(currentPath)}
                isSidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                layoutType={sideBarEmbedded ? LayoutTypes.sidebar : LayoutTypes.default}
            >
                {supportedLanguages.length > 1 && (
                    <>
                        <LanguageSwitcher
                            currentLanguage={i18n.language as Language}
                            onSelectLanguage={(lng) => i18n.changeLanguage(lng)}
                            supportedLanguages={supportedLanguages as Language[]}
                        />
                        {/*<span className="spacer"></span>*/}
                    </>
                )}
                {/*<ThemeSwitch />*/}
            </Header>
            <Routes>
                {/* Creating one route for each route path */}
                {Object.keys(RoutePaths).map((key) => {
                    const path = RoutePaths[key as keyof typeof RoutePaths];
                    return (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <>
                                    <Sidebar
                                        isOpen={sidebarOpen}
                                        setSidebarOpen={setSidebarOpen}
                                        contentData={useContentData()}
                                        id={'sidebar'}
                                    />
                                    <ContentList
                                        title={t(`pageTitle.${path.slice(1)}`) ?? ''}
                                        groups={useContentData()}
                                        layoutType={LayoutTypes.sidebar}
                                    />
                                </>
                            }
                        />
                    );
                })}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer
                layoutType={sideBarEmbedded ? LayoutTypes.sidebar : LayoutTypes.default}
                id={'footer'}
                languageType={i18n.language}
            />
            <BackToTop />
        </div>
    );
};

export default App;
