import React from 'react';
import { useTranslation } from 'react-i18next';

const Illegality: React.FC = () => {
    const { t } = useTranslation('termsOfUse', { keyPrefix: 'responsibilitiesLiabilities.misdemeanour.illegality' });

    return (
        <>
            <h4>{t('title')}</h4>
            <p>{t('content')}</p>
        </>
    );
};

export default Illegality;
