import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

import translationEN from './locales/en/translation.json';
import translationENCommunityGuidelines from './locales/en/communityGuidelines.json';
import translationENTermsOfUse from './locales/en/termsOfUse.json';
import translationENStyleGuide from './locales/en/styleGuide.json';
import translationDE from './locales/de/translation.json';
import translationDELegal from './locales/de/legal.json';
import translationDEPrivacyPolicy from './locales/de/privacyPolicy.json';
import translationDEAGBOVUser from './locales/de/AGBOVUser.json';
import translationDEAGBOVCorp from './locales/de/AGBOVCorp.json';
import translationDEAGBOVBooking from './locales/de/AGBOVBooking.json';
import translationDEAGBOVListings from './locales/de/AGBOVSpotlight.json';
import translationDEAGBOVSLA from './locales/de/AGBOVSLA.json';
import translationDECommunityGuidelines from './locales/de/communityGuidelines.json';
import translationDETermsOfUse from './locales/de/termsOfUse.json';

const languages = {
    en: {
        translation: translationEN,
        legal: translationDELegal,
        privacyPolicy: translationDEPrivacyPolicy,
        AGBOVUser: translationDEAGBOVUser,
        AGBOVCorp: translationDEAGBOVCorp,
        AGBOVBooking: translationDEAGBOVBooking,
        AGBOVListings: translationDEAGBOVListings,
        AGBOVSLA: translationDEAGBOVSLA,
        communityGuidelines: translationENCommunityGuidelines,
        termsOfUse: translationENTermsOfUse,
        styleGuide: translationENStyleGuide,
    },
    de: {
        translation: translationDE,
        legal: translationDELegal,
        privacyPolicy: translationDEPrivacyPolicy,
        AGBOVUser: translationDEAGBOVUser,
        AGBOVCorp: translationDEAGBOVCorp,
        AGBOVBooking: translationDEAGBOVBooking,
        AGBOVListings: translationDEAGBOVListings,
        AGBOVSLA: translationDEAGBOVSLA,
        communityGuidelines: translationDECommunityGuidelines,
        termsOfUse: translationDETermsOfUse,
        styleGuide: translationENStyleGuide,
    },
};
export const supportedLanguages = ['en', 'de'];

const params = new URLSearchParams(window.location.search);
const language = params.get('l') ?? Cookies.get('lang') ?? 'en';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        lng: language,
        resources: languages,
        supportedLngs: supportedLanguages,
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

document.documentElement.setAttribute('lang', i18n.language);
i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng);
    Cookies.set('lang', lng);
});

export default i18n;
