import Imprint from './Imprint';
import ContentLiability from './Disclaimer/ContentLiability';
import LinkLiability from './Disclaimer/LinkLiability';
import Copyright from './Disclaimer/Copyright';
import { getContentPage, GroupType } from '@overview-services/ovcomponents-react';

export const createContentDataLegal = (t: (key: string, options?: NonNullable<unknown>) => string): GroupType[] => [
    {
        groupTitle: t('imprint.title'),
        children: [getContentPage('', Imprint)],
    },
    {
        groupTitle: t('disclaimer.title'),
        children: [
            getContentPage(t('disclaimer.contentLiability.title'), ContentLiability),
            getContentPage(t('disclaimer.linkLiability.title'), LinkLiability),
            getContentPage(t('disclaimer.copyright.title'), Copyright),
        ],
    },
];
