import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    EfficientImage,
    EfficientImageZoom,
    Grid,
    GridArea,
    GridAreaAlign,
    GridAreaJustify,
} from '@overview-services/ovcomponents-react';

const LogoSymbol: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'logo.logoSymbol' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid count={2}>
                <GridArea>
                    <p>{t('content_1')}</p>
                    <p>{t('content_2')}</p>
                    <p>{t('content_3')}</p>
                </GridArea>
                <GridArea justify={GridAreaJustify.stretch} align={GridAreaAlign.stretch}>
                    <EfficientImage
                        url={'https://library.overview.de/pages/style-guide/symbol--image.png'}
                        blurHash={'00TSUA'}
                        zoom={EfficientImageZoom.fit}
                    ></EfficientImage>
                </GridArea>
            </Grid>
            <h4>{t('interpretation.title')}</h4>
            <Grid count={2}>
                <p>
                    <b>{t('interpretation.bullet_1')}</b> {t('interpretation.content_1')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_2')}</b> {t('interpretation.content_2')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_3')}</b> {t('interpretation.content_3')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_4')}</b> {t('interpretation.content_4')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_5')}</b> {t('interpretation.content_5')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_6')}</b> {t('interpretation.content_6')}
                </p>
                <p>
                    <b>{t('interpretation.bullet_7')}</b> {t('interpretation.content_7')}
                </p>
            </Grid>
        </>
    );
};

export default LogoSymbol;
