import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@overview-services/ovcomponents-react';

const Summary: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'summary' });

    return (
        <>
            <p>{t('content')}</p>
            <Accordion title={t('who.question')}>
                <p>{t('who.answer')}</p>
            </Accordion>
            <Accordion title={t('how.question')}>
                <p>{t('how.answer')}</p>
            </Accordion>
            <Accordion title={t('wherefore.question')}>
                <p>{t('wherefore.answer')}</p>
            </Accordion>
            <Accordion title={t('rights.question')}>
                <p>{t('rights.answer')}</p>
            </Accordion>
            <Accordion title={t('thirdParty.question')}>
                <p>{t('thirdParty.answer')}</p>
            </Accordion>
        </>
    );
};

export default Summary;
