import React from 'react';
import { useTranslation } from 'react-i18next';

const Penalty: React.FC = () => {
    const { t } = useTranslation('AGBOVSLA', { keyPrefix: 'penalty' });

    return (
        <>
            <p>{t('content')}</p>
        </>
    );
};

export default Penalty;
