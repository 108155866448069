import React from 'react';
import { useTranslation } from 'react-i18next';

const Definitions: React.FC = () => {
    const { t } = useTranslation('AGBOVSLA', { keyPrefix: 'definitions' });

    return (
        <>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
            <p>{t('content_3')}</p>
        </>
    );
};

export default Definitions;
