import Short from './Short';
import DataVisibility from './Original/DataVisibility';
import ServiceProcurance from './Original/ServiceProcurance';
import RatingSystem from './Original/RatingSystem';
import ReportPenalty from './Original/ReportPenalty';
import { getContentPage, GroupType } from '@overview-services/ovcomponents-react';

export const createContentDataCommunityGuidelines = (
    t: (key: string, options?: NonNullable<unknown>) => string,
): GroupType[] => [
    {
        groupTitle: t('short.title'),
        children: [getContentPage('', Short)],
    },
    {
        groupTitle: t('original.title'),
        children: [
            getContentPage(t('original.dataVisibility.title'), DataVisibility),
            getContentPage(t('original.serviceProcurance.title'), ServiceProcurance),
            getContentPage(t('original.ratingSystem.title'), RatingSystem),
            getContentPage(t('original.reportPenalty.title'), ReportPenalty),
        ],
    },
];
