import { getContentPage, GroupType } from '@overview-services/ovcomponents-react';
import Definitions from './Definitions';
import AvailabilityQuality from './AvailabilityQuality';
import Reporting from './Reporting';
import PriorityClasses from './PriorityClasses';
import Penalty from './Penalty';

export const createContentDataAGBOVSLA = (t: (key: string, options?: NonNullable<unknown>) => string): GroupType[] => [
    {
        groupTitle: t('definitions.title'),
        children: [getContentPage('', Definitions)],
    },
    {
        groupTitle: t('availabilityQuality.title'),
        children: [getContentPage('', AvailabilityQuality)],
    },
    {
        groupTitle: t('reporting.title'),
        children: [getContentPage('', Reporting)],
    },
    {
        groupTitle: t('priorityClasses.title'),
        children: [getContentPage('', PriorityClasses)],
    },
    {
        groupTitle: t('penalty.title'),
        children: [getContentPage('', Penalty)],
    },
];
