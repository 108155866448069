import React from 'react';
import { useTranslation } from 'react-i18next';

const GuidelineViolation: React.FC = () => {
    const { t } = useTranslation('termsOfUse', {
        keyPrefix: 'responsibilitiesLiabilities.misdemeanour.guidelineViolation',
    });

    return (
        <>
            <h4>{t('title')}</h4>
            <p>{t('content_1')}</p>
            <p>{t('content_2')}</p>
            <p>{t('content_3')}</p>
            <p>{t('content_4')}</p>
        </>
    );
};

export default GuidelineViolation;
