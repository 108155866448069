import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridArea } from '@overview-services/ovcomponents-react';

const AssetUsage: React.FC = () => {
    const { t } = useTranslation('styleGuide', { keyPrefix: 'introduction.assetUsage' });

    return (
        <>
            <h3>{t('title')}</h3>
            <Grid>
                <GridArea>
                    <p>{t('content_1')}</p>
                    <p>{t('content_2')}</p>
                    <p>{t('content_3')}</p>
                </GridArea>
            </Grid>
        </>
    );
};

export default AssetUsage;
