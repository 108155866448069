import React from 'react';
import { useTranslation } from 'react-i18next';

const Crisp: React.FC = () => {
    const { t } = useTranslation('privacyPolicy', { keyPrefix: 'support.crisp' });

    return (
        <>
            <h3>{t('title')}</h3>
            <p>
                {t('content')} <a href="https://crisp.chat/de/privacy/">https://crisp.chat/de/privacy/</a>
            </p>
        </>
    );
};

export default Crisp;
